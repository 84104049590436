


import * as React from 'react';
import {Provider} from 'react-redux';
import Store from './Store/configureStore';
import NavigationComponent from './Components/NavigationComponent';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import { StyleSheet} from 'react-native';

export default function App() {
console.log(Store.getState());



  return (
  <Provider store={Store} style={styles.generale}>
        <NavigationComponent />
    </Provider>
  );
}

const styles = StyleSheet.create({
  generale: {
      backgroundColor: "rgba(255,255,255,1)",
  }
});

serviceWorkerRegistration.register();