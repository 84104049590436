// Components/Navigation.js

//import React from 'react';
import { connect } from 'react-redux';
//import React, { useState, useEffect } from 'react';
import * as React from 'react';

import { StyleSheet, View, Text, ActivityIndicator, TextInput, Button, TouchableOpacity, Image } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import ActuList from '../Components/ActuList';
import ActuDetail from '../Components/ActuDetail';
import PageDetail from '../Components/PageDetail';


import AgendaList from '../Components/AgendaList';
import AgendaDetail from '../Components/AgendaDetail';

import CampingPresentation from '../Components/CampingPresentation';
import Store from '../Store/configureStore';

import SignInScreen from '../Components/SignInScreen';

import GLOBALS from './Globals';
import AsyncStorage from '@react-native-async-storage/async-storage';
//import { testCampingId } from '../API/CampingApi';

const AuthContext = React.createContext();




function SplashScreen() {
    return (
        <View>
            <Text>Loading...</Text>
        </View>
    );
}

function HomeScreen() {

    const styles = StyleSheet.create({
        login_button: {
            marginTop: 9,
            height: 60,
            backgroundColor: "rgba(31,175,191,1)",
            borderRadius: 5,
            justifyContent: "center",
            marginBottom: 68
        },
        login_text2: {
            color: "rgba(255,255,255,1)",
            alignSelf: "center",
            paddingLeft: 15,
            paddingRight: 15,
            fontSize: 20,
        },
        container_btn: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: "#fff"
        }
    });


    var IDCAMPING = GLOBALS.IDCAMPING;

    if (IDCAMPING) {
        return (
            <View style={styles.container_btn} >


            </View>
        );
    }
    else {
        return (
            <View style={styles.container_btn} >
                <TouchableOpacity
                    onPress={signOut}
                    style={styles.login_button}
                    activeOpacity={0.8}
                >
                    <Text style={styles.login_text2}>Déconnexion</Text>
                </TouchableOpacity>

            </View>
        );
    }


}

function signOut() {
    const action = { type: 'SIGN_OUT' };
    Store.dispatch(action);
    var IdCamping = "";
    clearStorage();



}


const clearStorage = async () => {
    try {
        await AsyncStorage.clear();
    } catch (e) {

    }
}



function HomeStackScreen() {
    var state = Store.getState();
    return (
        <Stack.Navigator>
            <Stack.Screen name="Accueil" component={CampingPresentation}
                options={{
                    title: 'Accueil',
                    headerStyle: {
                        backgroundColor: state.campingconfig.menuBackgroundCouleur,
                    },
                    headerTintColor: state.campingconfig.menuTexteCouleur,
                    headerTitleStyle: {
                        fontWeight: 'normal',
                    },
                }} />
            <Stack.Screen name="PageDetail" component={PageDetail}
                options={

                    ({ route }) => ({
                        title: route.params.titre,
                        headerStyle: {
                            backgroundColor: state.campingconfig.menuBackgroundCouleur,
                        },
                        headerTintColor: state.campingconfig.menuTexteCouleur,
                        headerTitleStyle: {
                            fontWeight: 'normal',
                        },
                    })}
            />
        </Stack.Navigator>
    );
}

function ActusStackScreen() {
    var state = Store.getState();
    return (
        <Stack.Navigator>
            <Stack.Screen name="Actualités" component={ActuList}
                options={{
                    title: 'Actualités',
                    headerStyle: {
                        backgroundColor: state.campingconfig.menuBackgroundCouleur,
                    },
                    headerTintColor: state.campingconfig.menuTexteCouleur,
                    headerTitleStyle: {
                        fontWeight: 'normal',
                    },
                }} />
            <Stack.Screen name="ActuDetail" component={ActuDetail}
                options={

                    ({ route }) => ({
                        title: route.params.titre,
                        headerStyle: {
                            backgroundColor: state.campingconfig.menuBackgroundCouleur,
                        },
                        headerTintColor: state.campingconfig.menuTexteCouleur,
                        headerTitleStyle: {
                            fontWeight: 'normal',
                        },
                    })}
            />
        </Stack.Navigator>
    );
}

function AgendaStackScreen() {
    var state = Store.getState();
    return (
        <Stack.Navigator>
            <Stack.Screen name="Agenda" component={AgendaList}
                options={{
                    title: 'Agenda',
                    headerStyle: {
                        backgroundColor: state.campingconfig.menuBackgroundCouleur,
                    },
                    headerTintColor: state.campingconfig.menuTexteCouleur,
                    headerTitleStyle: {
                        fontWeight: 'normal',
                    },
                }} />
            <Stack.Screen name="AgendaDetail" component={AgendaDetail}
                options={

                    ({ route }) => ({
                        title: route.params.titre,
                        headerStyle: {
                            backgroundColor: state.campingconfig.menuBackgroundCouleur,
                        },
                        headerTintColor: state.campingconfig.menuTexteCouleur,
                        headerTitleStyle: {
                            fontWeight: 'normal',
                        },
                    })}
            />
        </Stack.Navigator>
    );
}


function paramScreen() {
    var state = Store.getState();
    return (
        <Stack.Navigator>
            <Stack.Screen name="Paramètres" component={HomeScreen}
                options={{
                    title: 'Paramètres',
                    headerStyle: {
                        backgroundColor: state.campingconfig.menuBackgroundCouleur,
                    },
                    headerTintColor: state.campingconfig.menuTexteCouleur,
                    headerTitleStyle: {
                        fontWeight: 'normal',
                    },
                }} />
        </Stack.Navigator>
    );
}


const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();




class NavigationComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            HomeMessageError: 'This is Old Sample Text'
        };
    }




    render() {
        var state = Store.getState();

        var IDCAMPING = GLOBALS.IDCAMPING;

        if (IDCAMPING) {
            var message_accueil = 'Chargement'
        }
        else {
            var message_accueil = 'Bienvenue !'
        }

        return (

            <NavigationContainer>
                {state.isLoading ? (
                    // We haven't finished checking for the token yet
                    <Stack.Navigator>
                        <Stack.Screen name="Splash" component={SplashScreen} />
                    </Stack.Navigator>
                ) : state.userToken == null ? (
                    // No token found, user isn't signed in
                    <Stack.Navigator>
                        <Stack.Screen
                            name="SignIn"
                            component={SignInScreen}
                            options={{
                                title: message_accueil,
                                // When logging out, a pop animation feels intuitive
                                animationTypeForReplace: state.isSignout ? 'pop' : 'push',
                                headerStyle: {
                                    backgroundColor: "#bf814b",
                                },
                                headerTintColor: "#fff",
                                headerTitleStyle: {
                                    fontWeight: 'normal',
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                    fontSize: 25
                                },
                            }}
                        />
                    </Stack.Navigator>
                ) : (
                    // User is signed in
                    <Tab.Navigator
                        initialRouteName="Accueil"
                        tabBarOptions={{
                            activeTintColor: state.campingconfig.navigationTexteCouleur,
                            inactiveTintColor: state.campingconfig.navigationTexteCouleurInactive,
                            style: {
                                backgroundColor: state.campingconfig.navigationBackgroundCouleur
                            }
                        }}>
                        <Tab.Screen name="Accueil" component={HomeStackScreen}
                            options={{
                                tabBarLabel: 'Home',
                                tabBarIcon: ({ color, size }) => (
                                    <MaterialCommunityIcons name="home" color={color} size={size} />
                                ),
                            }} />
                        <Tab.Screen name="Actualités" component={ActusStackScreen}
                            options={{
                                tabBarLabel: 'Actus',
                                tabBarIcon: ({ color, size }) => (
                                    <MaterialCommunityIcons name="newspaper" color={color} size={size} />
                                ),
                            }} />
                        <Tab.Screen name="Agenda" component={AgendaStackScreen}
                            options={{
                                tabBarLabel: 'Agenda',
                                tabBarIcon: ({ color, size }) => (
                                    <MaterialCommunityIcons name="calendar" color={color} size={size} />
                                ),
                            }} />


                        <Tab.Screen name="Paramètres" component={paramScreen}
                            options={{
                                tabBarLabel: 'Paramètres',
                                tabBarIcon: ({ color, size }) => (
                                    <MaterialCommunityIcons name="access-point" color={color} size={size} />
                                ),
                            }} />

                    </Tab.Navigator>
                )}
            </NavigationContainer>
        );
    }
}


const mapStateToProps = (state) => {

    return {
        userToken: state.userToken,
        isSignout: state.isSignout,
        isLoading: state.is_loggin,
    }
}

//export default connect(mapStateToProps)(NavigationComponent)
export default connect(mapStateToProps)(NavigationComponent)
//export default NavigationComponent
